<template>
  <v-sheet elevation="2" class="my-7 pa-5">
    <v-row class="py-16">
      <v-col cols="12" lg="4" class="d-flex justify-center">
        <div class="circle">
          <div>
            <CountUp :endValue="6" />
            <h3>Years</h3>
          </div>
        </div>
      </v-col>
      <v-col cols="12" lg="8" class="d-flex align-center">
        <h2 class="text-lg-h3 text-h4 font-weight-bold">
          <span class="text-green-darken-2">Helping</span> Students Overcome
          Various Challenges
        </h2>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
export default {
  name: "Word",
};
</script>

<style scoped>
.shadow-one {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.testimony {
  border-radius: 8px;
}

.circle {
  height: 200px;
  width: 200px;
  color: whitesmoke;
  border: none;
  text-align: center;
  background-color: rgb(15, 125, 15);
  border-radius: 50%;
}
</style>
